<template>
  <v-container fluid>
    <div class="pb-5 px-15" style="background: #F4F9FF;">
      <v-card-title class="d-flex justify-between">
        <label class="item_title">aKinder™Chat</label>
        <div class="d-flex align-center mt-2">
          <v-btn color="#7024C4" dark class="ml-3" @click="onPromoCodes" small>
            <v-icon class="mr-2" size="16">mdi-ticket</v-icon>
            Promo code
          </v-btn>
          <v-switch
            v-model="enabledPromoCode"
            hide-details
            class="mt-0 ml-2"
            @change="onChangePromoCodeEnabled"
            :label="enabledPromoCode ? 'Enabled' : 'Disabled'"
          />
        </div>
      </v-card-title>
      <v-card-subtitle class="mt-2">
        <label class="item_description">
          Please manage the aKinder™Chat information:
        </label>
      </v-card-subtitle>
      <v-data-table
        :headers="headers"
        :items="families"
        :items-per-page="15"
        :loading="loading"
        class="elevation-0"
        :custom-sort="customSort"
        :search="search"
        :custom-filter="customFilter"
      >
        <template v-slot:[`item.image`]="{ item }">
          <v-avatar class="elevation-6 my-3 ml-5">
            <v-img :src="item.image ? item.image : placeholderImage" />
          </v-avatar>
        </template>
        <template v-slot:[`item.organization`]="{ item }">
          <div v-html="getFiltered(item.organization)" />
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <div v-html="getFiltered(item.email)" />
        </template>
        <template v-slot:[`item.phone`]="{ item }">
          <div v-html="getFiltered(formatPhoneNumber(item.phone))" />
        </template>
        <template v-slot:[`item.expire`]="{ item }">
          <div v-if="item.call_subscription_end_date">
            <div>{{ item.call_subscription }}</div>
            <div>by {{ formatDate(item.call_subscription_end_date) }}</div>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="onEditFamily(item)" v-bind="attrs" v-on="on"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
              </template>
              Edit
            </v-tooltip>
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="goToChat(item)" v-bind="attrs" v-on="on"
                  ><v-icon>mdi-message-outline</v-icon></v-btn
                >
              </template>
              Chat
            </v-tooltip> -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="onDeleteFamily(item)"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </template>
              Delete
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this family? This action can not be
          undone.</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteDialog = !deleteDialog"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="deleteAction"
            :loading="deleting"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <edit-family-dialog
      :dialog="editDialog"
      :onClose="onCloseDialog"
      :family="selected"
      v-if="editDialog"
    />
    <FamilyPromoCodesDialog
      :dialog="promoCodeDialog"
      :onClose="onCloseDialog"
      v-if="promoCodeDialog"
    />
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EditFamilyDialog from "../../components/admin/EditFamilyDialog.vue";
import FamilyPromoCodesDialog from "../../components/admin/FamilyPromoCodesDialog.vue";
import dateFormat from "dateformat";
export default {
  components: { EditFamilyDialog, FamilyPromoCodesDialog },
  data() {
    return {
      isSelectingPhoto: false,
      loading: false,
      errorMessage: null,
      snackbar: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
        passwordMatch: () =>
          this.profile.password != this.profile.confirmPassword
            ? `Password doesn't match`
            : "",
      },
      headers: [
        { text: "", value: "image", sortable: false },
        { text: "ID", value: "login_id" },
        { text: "Name", value: "name" },
        { text: "Gender", value: "gender" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Expire", value: "expire" },
        { text: "Actions", value: "actions" },
      ],
      selected: null,
      placeholderImage: require("@/assets/default.jpg"),
      profile: { email: "" },
      editDialog: false,
      deleteDialog: false,
      promoCodeDialog: false,
      deleting: false,
      search: "",
      families: [],
      enabledPromoCode: false,
    };
  },
  computed: {
    ...mapGetters({
      allFamilies: "family/getAllFamilies",
      keyword: "auth/getKeyword",
    }),
  },
  methods: {
    ...mapActions("family", {
      fetchAllFamilies: "fetchAllFamilies",
      addFamily: "addFamily",
      editFamily: "editFamily",
      deleteFamily: "deleteFamily",
      isEnabledPromoCode: "isEnabledPromoCode",
      setEnabledPromoCode: "setEnabledPromoCode",
    }),
    onChange() {
      this.errorMessage = null;
    },
    onEditFamily(item) {
      this.selected = item;
      this.editDialog = true;
    },
    onDeleteFamily(item) {
      this.selected = item;
      this.deleteDialog = true;
    },
    onDelete() {
      this.deleteDialog = true;
    },
    deleteAction() {
      this.deleting = true;
      this.deleteFamily(this.selected)
        .then(() => {
          this.deleting = false;
          this.selected = null;
          this.deleteDialog = false;
        })
        .catch((error) => {
          this.deleting = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
    formatDate(dateStr) {
      var date = new Date(dateStr);
      return dateFormat(this.convertTZ(date, ""), "mm/dd/yyyy hh:MM TT");
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York",
          }
        )
      );
    },
    customSort(items, index, isDesc) {
      if (index.length == 0 || index[0] === "full_name") {
        items.sort((a, b) => {
          var order = 0;
          var aStr = `${a["first_name"]} ${a["last_name"]}`;
          var bStr = `${b["first_name"]} ${b["last_name"]}`;

          if (aStr < bStr) order = -1;
          else if (aStr > bStr) order = 1;
          if (isDesc[0]) order *= -1;
          return order;
        });
      } else {
        items.sort((a, b) => {
          var order = 0;
          if (a[index] < b[index]) order = -1;
          else if (a[index] > b[index]) order = 1;
          if (isDesc[0]) order *= -1;
          return order;
        });
      }

      return items;
    },
    getFiltered(words) {
      if (!words) return "";
      var filterd = this.$options.filters.highlight(words, this.keyword);
      return filterd;
    },
    customFilter(value, search, item) {
      var searchFilter = false;
      if (this.keyword) {
        if (
          (`${item.first_name} ${item.last_name}` &&
            `${item.first_name} ${item.last_name}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.phone &&
            item.phone.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.phone &&
            this.formatPhoneNumber(item.phone)
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.address &&
            item.address.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.address2 &&
            item.address2.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.type &&
            item.type.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.email &&
            item.email.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.service &&
            item.service.toUpperCase().includes(this.keyword.toUpperCase()))
        ) {
          searchFilter = true;
        }
      } else {
        searchFilter = true;
      }
      return searchFilter;
    },
    goToChat(item) {
      this.$router.push({
        name: "chat-details",
        params: { id: "inbox" },
        query: {
          _id: item._id,
          user_type: "Family",
        },
      });
    },
    onCloseDialog() {
      this.editDialog = false;
      this.deleteDialog = false;
      this.promoCodeDialog = false;
    },
    onPromoCodes() {
      this.promoCodeDialog = true;
    },
    onChangePromoCodeEnabled() {
      this.setEnabledPromoCode({ value: this.enabledPromoCode })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
  },
  created() {
    this.loading = true;
    this.fetchAllFamilies()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
    this.isEnabledPromoCode()
      .then((res) => {
        this.enabledPromoCode = res == "true";
      })
      .catch((error) => {
        console.log(error);
      });
  },
  watch: {
    keyword(newValue) {
      this.search = newValue;
    },
    allFamilies(newValue) {
      if (this.$route.query.new) {
        this.families = newValue.filter((item) => {
          var startDate = new Date();
          startDate.setDate(startDate.getDate() - 7);
          var creatTime = new Date(item.createdAt);
          if (creatTime < startDate) {
            return false;
          }
          return true;
        });
      } else {
        this.families = newValue;
      }
    },
  },
};
</script>
<style scoped>
.item_title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
  padding-left: 5px;
  padding-top: 10px;
}
.item_description {
  color: #482684;
  /* font-family: 'Poppins-Bold'; */
  font-size: 18px;
  padding-left: 7px;
}
.family_title {
  color: #f0a000;
  font-family: "Poppins-SemiBold";
  font-size: 25px;
}
.users_image_section {
  padding-bottom: 20px;
  padding-top: 30px;
  padding-left: 40px;
  justify-content: center;
  max-width: 200px;
}
.edit_button {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.profile {
  overflow: visible;
}
.request_description_title {
  color: #7024c4;
  font-family: "Poppins-SemiBold";
  font-size: 15px;
}
.request_description_item {
  color: #482684;
  font-family: "Poppins-SemiBold";
  font-size: 12px;
}
/* .v-text-field >>> input {
    font-size: 10px;
  } */
.v-text-field >>> .v-text-field__details {
  display: none;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #feb934;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f5bd4d;
}

.v-text-field >>> input#map::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}
.v-text-field >>> input#map::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}
.v-text-field >>> input#map:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}
.v-text-field >>> input#map:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}
.v-text-field >>> input#map::placeholder {
  color: transparent;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"pb-5 px-15",staticStyle:{"background":"#F4F9FF"}},[_c('v-card-title',{staticClass:"d-flex justify-between"},[_c('label',{staticClass:"item_title"},[_vm._v("aKinder™Chat")]),_c('div',{staticClass:"d-flex align-center mt-2"},[_c('v-btn',{staticClass:"ml-3",attrs:{"color":"#7024C4","dark":"","small":""},on:{"click":_vm.onPromoCodes}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"16"}},[_vm._v("mdi-ticket")]),_vm._v(" Promo code ")],1),_c('v-switch',{staticClass:"mt-0 ml-2",attrs:{"hide-details":"","label":_vm.enabledPromoCode ? 'Enabled' : 'Disabled'},on:{"change":_vm.onChangePromoCodeEnabled},model:{value:(_vm.enabledPromoCode),callback:function ($$v) {_vm.enabledPromoCode=$$v},expression:"enabledPromoCode"}})],1)]),_c('v-card-subtitle',{staticClass:"mt-2"},[_c('label',{staticClass:"item_description"},[_vm._v(" Please manage the aKinder™Chat information: ")])]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.families,"items-per-page":15,"loading":_vm.loading,"custom-sort":_vm.customSort,"search":_vm.search,"custom-filter":_vm.customFilter},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"elevation-6 my-3 ml-5"},[_c('v-img',{attrs:{"src":item.image ? item.image : _vm.placeholderImage}})],1)]}},{key:"item.organization",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.organization))}})]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.email))}})]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(_vm.formatPhoneNumber(item.phone)))}})]}},{key:"item.expire",fn:function(ref){
var item = ref.item;
return [(item.call_subscription_end_date)?_c('div',[_c('div',[_vm._v(_vm._s(item.call_subscription))]),_c('div',[_vm._v("by "+_vm._s(_vm.formatDate(item.call_subscription_end_date)))])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.onEditFamily(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" Edit ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.onDeleteFamily(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_vm._v(" Delete ")])],1)]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this family? This action can not be undone.")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.deleteDialog = !_vm.deleteDialog}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.deleting},on:{"click":_vm.deleteAction}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1),(_vm.editDialog)?_c('edit-family-dialog',{attrs:{"dialog":_vm.editDialog,"onClose":_vm.onCloseDialog,"family":_vm.selected}}):_vm._e(),(_vm.promoCodeDialog)?_c('FamilyPromoCodesDialog',{attrs:{"dialog":_vm.promoCodeDialog,"onClose":_vm.onCloseDialog}}):_vm._e(),_c('v-snackbar',{model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title
        style="display: flex; flex-direction: row; justify-content: space-between; font-family: 'Poppins-SemiBold'"
      >
        Promo codes
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="d-flex align-center">
        <div>
          <v-select
            solo
            flat
            outlined
            dense
            v-model="newType"
            :items="typeOptions"
            item-text="text"
            item-value="id"
            class="w-100"
            style="font-family:'Poppins-Regular';"
            hide-details
            label="Type"
          />
        </div>
        <v-btn @click="onAddPromoCode" class="ml-5"
          >Generate New Promo Code</v-btn
        >
      </v-card-text>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="5"
          class="elevation-0"
          :loading="loading"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div>
              <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="onEditBalance(item)"
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon>mdi-pencil</v-icon></v-btn
                    >
                  </template>
                  Edit
                </v-tooltip> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="onDeletePromoCode(item)"
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon>mdi-close</v-icon></v-btn
                  >
                </template>
                Delete
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.applied`]="{ item }">
            <div v-if="item.user">Applied</div>
          </template>
          <template v-slot:[`item.user_id`]="{ item }">
            <div v-if="item.user">{{ item.user.name }}</div>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <div>{{ getTypeName(item.type) }}</div>
          </template>
          <template v-slot:[`item.expire_date`]="{ item }">
            <div v-if="item.expire_date">
              {{ formatDate(item.expire_date) }}
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-snackbar v-model="snackbar">
        {{ snackMessage }}
      </v-snackbar>
      <confirm-delete-dialog
        :deleteDialog="deleteDialog"
        :onClose="onCloseConfirmDelete"
        :onConfirm="onConfirmDelete"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog.vue";
import dateFormat from "dateformat";
export default {
  components: { ConfirmDeleteDialog },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
    },
  },
  data() {
    return {
      loading: false,
      items: [],
      headers: [
        { text: "Code", value: "code" },
        { text: "Type", value: "type" },
        { text: "Price", value: "price" },
        { text: "Applied", value: "user_id" },
        { text: "Expire", value: "expire_date" },
        { text: "Actions", value: "actions" },
      ],
      typeOptions: [
        { id: "boomrchatcall1v1", text: "Call Service 1 to 1" },
        { id: "boomrchatcallgroupv1", text: "Call Service Group" },
        // { id: "boomrchatfamily5", text: "Family plan for 5+" },
        // { id: "boomrchatfamily10", text: "Family plan for 10+" },
      ],
      newType: "",
      snackbar: false,
      snackMessage: null,
      deleteDialog: false,
      selected: null,
    };
  },
  methods: {
    ...mapActions("promo_code", {
      fetchAllPromoCodes: "fetchAllPromoCodes",
      addPromoCode: "addPromoCode",
      deletePromoCode: "deletePromoCode",
    }),
    onAddPromoCode() {
      if (!this.newType) {
        this.showSnackbar("Please select the promo type.");
        return;
      }
      this.loading = true;
      this.addPromoCode({ type: this.newType })
        .then((res) => {
          this.items.unshift(res);
          console.log(res);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response.data.message);
          this.loading = false;
        });
    },
    onCloseConfirmDelete() {
      this.deleteDialog = false;
    },
    onConfirmDelete() {
      console.log(this.selected);
      this.loading = true;
      this.deletePromoCode({ _id: this.selected })
        .then((res) => {
          this.items = this.items.filter((item) => item._id != res._id);
          this.loading = false;
          this.selected = null;
          this.deleteDialog = false;
        })
        .catch((error) => {
          console.log(error.response.data.message);
          this.loading = false;
          this.deleteDialog = false;
        });
    },
    onDeletePromoCode(item) {
      this.deleteDialog = true;
      this.selected = item._id;
    },
    showSnackbar(message) {
      this.snackbar = true;
      this.snackMessage = message;
    },
    formatDate(dateStr) {
      var date = new Date(dateStr);
      return dateFormat(this.convertTZ(date, ""), "mm/dd/yyyy hh:MM TT");
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York",
          }
        )
      );
    },
    getTypeName(type) {
      let item = this.typeOptions.find((t) => t.id == type);
      if (item) return item.text;
      return "";
    },
  },
  mounted() {
    this.loading = true;
    this.fetchAllPromoCodes()
      .then((res) => {
        this.items = res;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error.response.data.message);
        this.loading = false;
      });
  },
};
</script>
<style scoped>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}

.text-transform-none {
  text-transform: none;
}
</style>
